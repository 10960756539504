import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import iosInnerHeight, * as ios from 'ios-inner-height'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
// import * as viewportSize  from './viewportSize.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

import { Light, Loader, NearestFilter } from 'three'


/* *** SCROLL TRIGGER
// Scroll Trigger
// ***
// gsap.registerPlugin(ScrollTrigger)
//
// let mysecs = gsap.utils.toArray(".section");
//
// gsap.to(mysecs, {
//   yPercent: -100 * (mysecs.length - 1),
//   ease: "none",
//   scrollTrigger: {
//     trigger: ".container",
//     pin: true,
//     scrub: 1,
//     snap: 1 / (mysecs.height - 1),
//     // base vertical scrolling on how wide the container is so it feels more natural.
//     end: "+=3500",
//   }
// });
*/


/**
 * Canvas definition
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

/**
 * Sizes
 */
 const sizes = {

    width: window.innerWidth,
    height: iosInnerHeight()
}

// ***
// Scene
// ***

const scene = new THREE.Scene()

scene.environment = new RGBELoader().load('textures/equirectangular/royal_esplanade_1k.hdr')
scene.environment.mapping = THREE.EquirectangularReflectionMapping

// ***
// Overlay
// ***
const overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
const overlayMaterial = new THREE.ShaderMaterial( {
    transparent: true,
    uniforms: {
        uAlpha: { value: 1 }
    },
    vertexShader:`
        void main () {
            gl_Position = vec4(position, 1.0);

        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main() {
            gl_FragColor = vec4(0.003, 0.180, 0.250, uAlpha);
        }
    `


} )
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)

// ***
// Loaders
// ***
const loadingBarElement = document.querySelector('.loading-bar')

const loadingManager = new THREE.LoadingManager(
    // loaded
    () =>  {
        console.log("LOAD loaded")
        gsap.delayedCall( 0.5, () => {
            gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value: 0 })
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        })
    },
    // progress
    (itemUrl, itemLoaded, itemsTotal) =>  {
       const progressRate =  itemLoaded / itemsTotal
       loadingBarElement.style.transform = `scaleX(${progressRate})`
        
    }

) 

const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( 'js/libs/draco/gltf/' );

let modelLoadersIndex = 3

const gltfLoader = new GLTFLoader(loadingManager)

let mixer = null
let c4dCamera = null

let mFullScene = null
gltfLoader.load( 'models/gltf/GesamtModell.glb', function (gltf) {
    const model = gltf.scene

    scene.add(model);
    console.log("gesamtmodell", gltf)
    console.log("find", model.getObjectByName("Warbear"))
    model.scale.set(0.025,0.025,0.025)
    
    c4dCamera = gltf.cameras[0]
    c4dCamera.aspect = sizes.width / sizes.height
    c4dCamera.updateProjectionMatrix()

    mixer = new THREE.AnimationMixer( model )
    const anim = mixer.clipAction( gltf.animations[0])
    // dont touch anim.setLoop for now
    anim.play()

    mFullScene = model
    modelLoadersIndex -= 1

})

let mFinalCubeMixer = null
let mFinalCube = null
let mFinalCubeScene = null
gltfLoader.load('models/gltf/FinalSculpture_Single.glb', function (gltfFinalCube) {
    const model = gltfFinalCube.scene
    const scale = 0.025
     
    mFinalCube = model.children[0]
    console.log("finalcube", gltfFinalCube)
    // model.children[0].material.opacity = 0
    // model.children[0].material.transparent = true

    model.scale.set(scale, scale, scale)
    // model.position.set( -4820.823*scale, 919.176*scale, -5173*scale)

    scene.add(model)
    mFinalCubeScene = model

    // get the animation channel
    // mFinalCubeMixer = new THREE.AnimationMixer( gltfFinalCube.scene )
    // const mFinalCubeAnim = mFinalCubeMixer.clipAction( gltfFinalCube.animations[0])
    // mFinalCubeAnim.play()
    modelLoadersIndex -= 1
  
})

let mBusMixer = null
let mBusScene = null
gltfLoader.load('models/gltf/mBus.glb', function (gltfBus) {
    const model = gltfBus.scene
    
    const mBus = model.children[0]
 
    console.log("busmodell", gltfBus)

    scene.add(model)
  
  
    model.position.set(-125.67, 0, 3.6)
 
    model.scale.set(0.025,0.025,0.025)

    mBusMixer = new THREE.AnimationMixer( gltfBus.scene )
    const mBusAnim  = mBusMixer.clipAction( gltfBus.animations[0])
    mBusAnim.loop = THREE.LoopRepeat
    mBusAnim.play()

    mBusScene = model

    modelLoadersIndex -= 1
})


/**
 * Initialize Video Textures
 */

const wfVideoA = document.getElementById('wfvid_Riesenposter')
wfVideoA.play()
const wfVideoATexture = new THREE.VideoTexture(wfVideoA)
wfVideoATexture.encoding = THREE.sRGBEncoding
console.log("video A", wfVideoATexture)
wfVideoATexture.image.muted = true
const wfVideoA_parameters = { color: 0xffffff, map: wfVideoATexture };

const wfVideoB = document.getElementById('wfvid_Strassenuhr')
wfVideoB.play()
const wfVideoBTexture = new THREE.VideoTexture(wfVideoB)
wfVideoBTexture.encoding = THREE.sRGBEncoding
console.log("video B", wfVideoBTexture)
wfVideoBTexture.image.muted = true
const wfVideoB_parameters = { color: 0xffffff, map: wfVideoBTexture };

const wfVideoC = document.getElementById('wfvid_Stromkasten')
wfVideoC.play()
const wfVideoCTexture = new THREE.VideoTexture(wfVideoC)
wfVideoCTexture.encoding = THREE.sRGBEncoding
console.log("video C", wfVideoCTexture)
wfVideoCTexture.image.muted = true
const wfVideoC_parameters = { color: 0xffffff, map: wfVideoCTexture };

const wfVideoD = document.getElementById('wfvid_Posterwand')
wfVideoD.play()
const wfVideoDTexture = new THREE.VideoTexture(wfVideoD)
wfVideoDTexture.encoding = THREE.sRGBEncoding
console.log("video D", wfVideoDTexture)
wfVideoDTexture.image.muted = true
const wfVideoD_parameters = { color: 0xffffff, map: wfVideoDTexture };

const wfVideoE = document.getElementById('wfvid_Bushaltestelle')
wfVideoE.play()
const wfVideoETexture = new THREE.VideoTexture(wfVideoE)
wfVideoETexture.encoding = THREE.sRGBEncoding
console.log("video D", wfVideoETexture)
wfVideoETexture.image.muted = true
const wfVideoE_parameters = { color: 0xffffff, map: wfVideoETexture };


/* *** INITIALIZE SCENE ********************************************
*/ 
function initScene() {
    const wf_Riesenposter = mFullScene.getObjectByName("mStrassenzugwfRiesenposter")
    if (wf_Riesenposter.type == "Mesh") {
        wf_Riesenposter.material.map = wfVideoATexture
        wf_Riesenposter.material.needsUpdate = true
    }

    const wf_Strassenuhr = mFullScene.getObjectByName("mStrassenzugwfStrassenuhr")
    if (wf_Strassenuhr.type == "Mesh") {
        wf_Strassenuhr.material.map = wfVideoBTexture
        wf_Strassenuhr.material.needsUpdate
    }

    const wf_Stromkasten = mFullScene.getObjectByName("mStrassenzugwfStromkasten")
    if (wf_Stromkasten.type == "Mesh") {
        // console.log(wf_Stromkasten)
        wf_Stromkasten.material.map = wfVideoCTexture
        wf_Stromkasten.material.needsUpdate
    }

    const wf_Posterwand = mFullScene.getObjectByName("mWallwfPosterwand")
    if (wf_Posterwand.type == "Mesh") {
        // console.log(wf_Posterwand)
        wf_Posterwand.material.map = wfVideoDTexture
        wf_Posterwand.material.needsUpdate
    }

     const wf_Busstation = mFullScene.getObjectByName("mWallwfBusstation")  
    if (wf_Busstation.type == "Mesh" ) {

        // console.log(wf_Busstation)
        wf_Busstation.material.map = wfVideoETexture
        wf_Busstation.material.needsUpdate
    }

    // const wf_FinalCube = mFinalCubeScene.getObjectByName("wfs_C")
    // if (wf_FinalCube.type == "Mesh") {
    //     wf_FinalCube.material.map = wfVideoBTexture
    //     wf_FinalCube.material.needsUpdate
    // }

    // const wf_BusPoster = mBusScene.getObjectByName("wf_BusSeite")
    // if (wf_BusPoster.type == "Mesh") {
    //     wf_BusPoster.material.map = wfVideoATexture
    //     wf_BusPoster.material.needsUpdate
    // }

    c4dCamera.near = 1
    c4dCamera.far = 8000
    c4dCamera.updateProjectionMatrix()
    console.log( c4dCamera.far)
    console.log( "near", c4dCamera.near)

}

/* *** PARTICLES ***************************************************
// Particles
*/ 

const parameters = {
    materialColor: '#ffeded'
}
const particlesCount = 5000
const positions = new Float32Array(particlesCount*3)
const particleHeight = 20
const particleXWidth = 400
const particleZWidth = 400

for (let i=0; i<particlesCount; i++) {
    positions[i*3+0] = (Math.random() - .5) * particleXWidth
    positions[i*3+1] = particleHeight - (Math.random()*particleHeight)
    positions[i*3+2] = (Math.random() -.5 ) * particleZWidth
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute( positions, 3) )
const particleMaterial = new THREE.PointsMaterial( {
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 5
})

const particles = new THREE.Points(particlesGeometry, particleMaterial)
scene.add(particles)

// *** 
// Raycaster 
// ***

const raycaster = new THREE.Raycaster()
let INTERSECTED

// ***
// Lights
// ***
 const directionalLight = new THREE.DirectionalLight( '#ffffff', 1)
 directionalLight.position.set(1,1,0)
 scene.add(directionalLight)

 const ambientLight = new THREE.AmbientLight('#ffffff',1)
 scene.add(ambientLight)

/**
 * Camera
 */

// Base camera, though we use the C4D one from the laoder
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 1, 30)
camera.position.z = 6

const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

cameraGroup.add(camera)


// ***
// Fog
// ***

scene.fog = new THREE.FogExp2( 0x011526, 0.0004 );

// ***
// Renderer
// ***

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true,
    powerPreference: 'high-performance'
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

renderer.outputEncoding = THREE.sRGBEncoding;
renderer.premultipliedAlpha = false
 renderer.physicallyCorrectLights = true;

// Just render this once for the loading canvas
 renderer.render(scene, camera)

 
// ***
// Audio
// ***

const listener = new THREE.AudioListener();
camera.add( listener );

// create a global audio source
const sound = new THREE.Audio( listener );

// load a sound and set it as the Audio object's buffer
const audioLoader = new THREE.AudioLoader();
audioLoader.load( 'sounds/FreeSound_CityAmbienceBerlin.mp3', function( buffer ) {
	sound.setBuffer( buffer );
	sound.setLoop( true );
	sound.setVolume( 0.5 );
	sound.play();
});

/* *** BROWSER EVENTS *****************************************
// Hook into the web browser
*/

// Resize event
window.addEventListener('resize', () =>
{
    // Update sizes

    sizes.width  = window.innerWidth
    sizes.height = iosInnerHeight()
    console.log("New height:", sizes.height)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    c4dCamera.aspect = sizes.width / sizes.height
    c4dCamera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
})

// Scroll event
let scroll = window.scrollY
let scrollRatio = Math.min(Math.max(scrollY/window.innerHeight-.01,0),12.99)
let scrollRatioB = Math.min(Math.max(scrollY/document.documentElement.clientHeight-.01,0),12.99)

window.addEventListener( 'scroll', () => {
    scroll = window.scrollY
    scrollRatio = Math.min(Math.max(scroll/sizes.height-.01,0),12.99)
    
} )

// // Move event
// const cursor = {}
// cursor.x = 0
// cursor.y = 0

// window.addEventListener('mousemove', (event) => {
//     cursor.x = (event.clientX / sizes.width) -.5
//     cursor.y = (event.clientY / sizes.height) -.5

// })

const pointer = new THREE.Vector2()
    
window.addEventListener( 'mousemove', (event) => {
    pointer.x = ( event.clientX / sizes.width ) * 2 - 1;
    pointer.y = - ( event.clientY / sizes.height) * 2 + 1;
})


// ***
//  Animation setup
// ****
const clock = new THREE.Clock()
let previousTime = 0
let previousScroll = 10

// ***
// Content 
// ***
const sections = [
    {   start:  1, 
        end:    2,
        id:     "canvIntro"
    },
    {   start:  2, 
        end:    3,
        id:     "canvUeberUns"
    },
    {
        start: 3,
        end:   4,
        id:     "canvWasWirTun"
    },
    {
        start: 4,
        end:   5,
        id:     "canvRiesenposter"
    },
    {
        start: 5,
        end:   6,
        id:     "canvUhrenkasten"
    },
    {
        start: 7,
        end:   8,
        id:     "canvStromkasten"
    },
    {
        start: 8,
        end:   9,
        id:     "canvBushaltestelle"
    },
    {
        start: 9,
        end:   10,
        id:     "canvPlakatwand"
    },
    {
        start: 10,
        end:   11,
        id:     "canvSocialMediaA"
    },
    {
        start: 11,
        end:   12,
        id:     "canvSocialMediaB"
    },
    {
        start: 12,
        end:   13,
        id:     "canvTreppeUnten"
    }

]

/* *** DEBUG
// const gui = new dat.GUI()
//
//
// gui
//     .addColor(parameters, 'materialColor')
//     .onChange( () => {
//         material.color.set(parameters.materialColor)
//     })
*/

/* *** MAIN ANIMATION LOOP *************************
*/
let init_scene = 1
let camx = null
let camy = null
const tick = () =>
{

    // var info = document.getElementById("debugCanvas")
    // info.innerText = "Scroll:    " + scroll + "\nSizes Height : " + sizes.height + "\nScroll Ratio: " + scrollRatio

    window.requestAnimationFrame(tick)

    // Don't do anything until we are loaded!
    if (modelLoadersIndex > 0) {
        return
    }
    if ( init_scene == 1 ) {
        initScene()
        init_scene = 0
    }

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
 
    for ( let section of sections ) {
        const mc = document.getElementById(section.id)
        if ( mc != null ) {
 
            if (scroll >= (section.start-.5) * sizes.height && scroll < (section.end-.5)* sizes.height ) {
                const mc = document.getElementById(section.id)
                mc.classList.add("visible");
            }
            else {
                mc.classList.remove("visible");
            }
        }
    }    

    // Go through the scroll sections to activate and hide and show parts of
    // the model. This might also heavily influence performance...

    const modStr = mFullScene.getObjectByName('mStrassenzugTop')
    const modTur = mFullScene.getObjectByName('mTurmTop')
    const modSoc = mFullScene.getObjectByName('mSaeulenTop')
    const modFin = mFullScene.getObjectByName('mStairNull')
    const modWal = mFullScene.getObjectByName('mWallTop')
    const modBus = mBusScene.getObjectByName('Bus')
    switch( Math.floor(scrollRatio)) {

        case 0:
        case 1:
                    modTur.visible = true
                    modStr.visible = false
                    modWal.visible = false
                    modBus.visible = false
                    modSoc.visible = false
                    modFin.visible = false
                    break;
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
                    modTur.visible = true
                    modStr.visible = true
                    modWal.visible = true
                    modBus.visible = false
                    modSoc.visible = false
                    modFin.visible = false
                    break;


        case 8:
        case 9:
                    modTur.visible = true
                    modStr.visible = true
                    modWal.visible = true
                    modBus.visible = true
                    modSoc.visible = true
                    modFin.visible = false
                    break;


        case 10:
        case 11:
        case 12: 
                    modTur.visible = false
                    modStr.visible = false
                    modWal.visible = false
                    modBus.visible = false
                    modSoc.visible = true
                    modFin.visible = true
                    mFinalCube.rotation.y = 0.1*elapsedTime;
                    break;
    }

    

    const delta = clock.getDelta()
    if(mixer !== null) {
        // console.log(window.pageYOffset/sizes.height)
        if (scroll !== previousScroll) {
            mixer.setTime(scrollRatio)
            mixer.update(delta)

            previousScroll = scroll
            camx = c4dCamera.position.x
            camy = c4dCamera.position.y
        }
    }
    if (mBusMixer !== null ) {
        mBusMixer.setTime(elapsedTime)
        mBusMixer.update(delta)
        
    }
    // console.log(mixer)

    // Raycasting for last object
    if (scrollRatio >= 12.5 ) {

        // mFinalCubeMixer.setTime(Math.abs(pointer.x)*1.5)
        // mFinalCubeMixer.update(delta)

        // Raycasting for last object
        // raycaster.setFromCamera( pointer, c4dCamera );
        // const intersects = raycaster.intersectObjects( scene.children, true );
        // if ( intersects.length > 0 ) {
            // console.log( "intersect", intersects[0].object)
            // const intersected = intersects[0].object
            
            // const fcmaterial = mFinalCube.getObjectByName("wfs_A").material


            // if ( intersected.name == "wfs_A" || 
            //     intersected.name == "wfs_B" ||
            //     intersected.name == "wfs_C" ||
            //     intersected.name == "wfs_D"   ) {
            //     // if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );

            //     // INTERSECTED = intersects[ 0 ].object;
            //     // INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
            //     fcmaterial.emissive.setHex( 0xffff00 );
            //     } else {
            //     // if ( INTERSECTED ) INTERSECTED.material.emissive.setHex( INTERSECTED.currentHex );
            //     fcmaterial.emissive.setHex( 0x000000 )
            // }
        

        // } 
    }   

    
    // Render

    c4dCamera.position.x = camx + (pointer.x*50)
    c4dCamera.position.y = camy + (pointer.y*50)

    renderer.render(scene, c4dCamera)
    
    // if(c4dCamera !== null) {
    //     renderer.render(scene, c4dCamera)
    // }    
    // // Call tick again on the next frame

}

tick()